import React from "react"
import { RedocStandalone } from 'redoc';
import { Helmet } from "react-helmet";

export default (props) => 
<>
    <Helmet title="Aaptiv Enterprise API">
        <link rel="icon" href="https://aaptiv.com/assets/images/favicon2018.png" />        
    </Helmet>
    <RedocStandalone spec={props.spec} />
</>
